"use client"

import React from "react";

import { useRouter } from "next/navigation";
import Image from "next/image";
import Link from "next/link";

import { AuthenticationStyled } from "@vorderseite/layouts";
import { notoSerif } from "@vorderseite/styles/fonts";

import { AuthenticationService } from "@vorderseite/services/authentication_services";

import { DynamicScalingViewport } from "@vorderseite/utils/viewport";

import { Box, Button, Group, Input, PasswordInput, Stack, Text, Title, UnstyledButton } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useForm } from '@mantine/form';

import { IconFlag3Filled, IconSignature } from "@tabler/icons-react";

import DefaultBlurryGradientImage from "./../../public/default_blurry_gradient.jpg";

export default function AuthenticationPage(): Readonly<React.ReactElement> {
    void DynamicScalingViewport();

    const router = useRouter();

    const form = useForm({
        initialValues: {
            username: "",
            password: "",
        },

        validate: {
            username: (value) => (/^[A-Za-z][A-Za-z0-9_]{5,24}$/.test(value) ? null : "รูปแบบชื่อผู้ใช้งานไม่ถูกต้อง"),
        },
    });

    const OnSubmitAuthenticationForm = async (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();

        const authenticationNotify = notifications.show({
            withBorder: true,
            autoClose: false,
            title: <Text fw={700} size={'lg'}>กำลังเข้าสู่ระบบ</Text>,
            message: <Text size={'lg'}>กรุณารอการยืนยันตัวตนจากระบบสักครู่</Text>,
            color: 'yellow',
            loading: true,
        });

        try {
            const response = await AuthenticationService.SignIn(form.values);
            localStorage.setItem("access_token", response.data.access_token);

            notifications.update({
                id: authenticationNotify,
                withBorder: true,
                autoClose: 4096,
                title: <Text fw={700} size={'lg'}>{response.message}</Text>,
                message: <Text size={'lg'}>กำลังนำคุณไปยังหน้าการจัดการหลัก</Text>,
                color: 'green',
                loading: false,
            });
            void router.push("/chats");

            return;

        } catch ({ message }: any) {
            notifications.update({
                id: authenticationNotify,
                withBorder: true,
                autoClose: 4096,
                title: <Text fw={700} size={'lg'}>ไม่สามารถเข้าสู่ระบบได้</Text>,
                message: <Text size={'lg'}>{message}</Text>,
                color: 'red',
                loading: false,
            });
            return;
        }
    }

    React.useEffect(() => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
            void router.push("/chats");
        }

        return () => {};
    }, [router]);

    return (
        <AuthenticationStyled>
            <Stack
                component={'form'}
                w={'100%'}
                h={'100%'}
                p={32}
                maw={"100%"}
                justify={'space-between'}
                bg={'#ffffff'}
                hiddenFrom={'md'}
                onSubmit={(event) => void OnSubmitAuthenticationForm(event)}
            >
                <Stack gap={'xl'}>
                    <UnstyledButton
                        component={Link}
                        c={'#737373'}
                        href={{pathname: "/"}}
                    >
                        <Group gap={'xs'}>
                            <IconFlag3Filled />
                            <Text fw={600}>ติดต่อผู้พัฒนา</Text>
                        </Group>
                    </UnstyledButton>
                    <Stack gap={8}>
                        <Title
                            className={notoSerif.className}
                            fw={700}
                            fz={30}
                        >
                            LINE Chat&apos;s ATAuto
                        </Title>
                        <Group gap={4} c={'#737373'}>
                            <Text fz={18}>คุณยังไม่มีบัญชีงั้นเหรอ? คุณต้องติดต่อ</Text>
                            <UnstyledButton
                                component={Link}
                                c={'#ecb31d'}
                                fw={600}
                                fz={18}
                                href={{pathname: "/"}}
                            >
                                ผู้ดูแลแชท
                            </UnstyledButton>
                        </Group>
                    </Stack>
                    <Stack gap={'xl'}>
                        <Input.Wrapper
                            size={'lg'}
                            withAsterisk
                            label="ชื่อผู้ใช้งาน"
                            description="กรุณาใส่ชื่อผู้ใช้งานของคุณเพื่อยืนยันตัวตน"
                        >
                            <Input
                                size={'md'}
                                placeholder="ชื่อผู้ใช้งานของคุณ"
                                {...form.getInputProps('username')}
                            />
                        </Input.Wrapper>
                        <Input.Wrapper
                            size={'lg'}
                            withAsterisk
                            label="รหัสผ่าน"
                            description="กรุณาใส่รหัสผ่านของคุณเพื่อความปลอดภัย"
                        >
                            <PasswordInput
                                mt={5}
                                size={'md'}
                                placeholder="รหัสผ่านของชื่อผู้ใช้งานข้างต้น"
                                {...form.getInputProps('password')}
                            />
                        </Input.Wrapper>
                    </Stack>
                </Stack>
                <Button
                    type="submit"
                    variant={'filled'}
                    color={'#f2b105'}
                    size={'lg'}
                    radius={'md'}
                    c={'dark'}
                >
                    <Group gap={4}>
                        <IconSignature size={32} />
                        <Text fz={20} fw={700}>เข้าสู่ระบบ</Text>
                    </Group>
                </Button>
            </Stack>
            <Stack
                component={'form'}
                w={'100%'}
                h={'100%'}
                p={32}
                maw={500}
                justify={'space-between'}
                bg={'#ffffff'}
                visibleFrom={'md'}
                onSubmit={(event) => void OnSubmitAuthenticationForm(event)}
            >
                <Stack gap={'xl'}>
                    <UnstyledButton
                        component={Link}
                        c={'#737373'}
                        href={{pathname: "/"}}
                    >
                        <Group gap={'xs'}>
                            <IconFlag3Filled />
                            <Text fw={600}>ติดต่อผู้พัฒนา</Text>
                        </Group>
                    </UnstyledButton>
                    <Stack gap={8}>
                        <Title
                            className={notoSerif.className}
                            fw={700}
                            fz={30}
                        >
                            LINE Chat&apos;s ATAuto
                        </Title>
                        <Group gap={4} c={'#737373'}>
                            <Text fz={18}>คุณยังไม่มีบัญชีงั้นเหรอ? คุณต้องติดต่อ</Text>
                            <UnstyledButton
                                component={Link}
                                c={'#ecb31d'}
                                fw={600}
                                fz={18}
                                href={{pathname: "/"}}
                            >
                                ผู้ดูแลแชท
                            </UnstyledButton>
                        </Group>
                    </Stack>
                    <Stack gap={'xl'}>
                        <Input.Wrapper
                            size={'lg'}
                            withAsterisk
                            label="ชื่อผู้ใช้งาน"
                            description="กรุณาใส่ชื่อผู้ใช้งานของคุณเพื่อยืนยันตัวตน"
                        >
                            <Input
                                size={'md'}
                                placeholder="ชื่อผู้ใช้งานของคุณ"
                                {...form.getInputProps('username')}
                            />
                        </Input.Wrapper>
                        <Input.Wrapper
                            size={'lg'}
                            withAsterisk
                            label="รหัสผ่าน"
                            description="กรุณาใส่รหัสผ่านของคุณเพื่อความปลอดภัย"
                        >
                            <PasswordInput
                                mt={5}
                                size={'md'}
                                placeholder="รหัสผ่านของชื่อผู้ใช้งานข้างต้น"
                                {...form.getInputProps('password')}
                            />
                        </Input.Wrapper>
                    </Stack>
                </Stack>
                <Button
                    type={'submit'}
                    variant={'filled'}
                    color={'#f2b105'}
                    size={'lg'}
                    radius={'md'}
                    c={'dark'}
                >
                    <Group gap={4}>
                        <IconSignature size={32} />
                        <Text fz={20} fw={700}>เข้าสู่ระบบ</Text>
                    </Group>
                </Button>
            </Stack>
            <Box
                component={'div'}
                w={'100%'}
                h={'100%'}
                pos={'relative'}
                maw={'100%'}
                bg={'#ececec'}
                visibleFrom={'md'}
            >
                <Image
                    src={DefaultBlurryGradientImage}
                    alt={"yellow blurry gradient wallpaper"}
                    priority
                    fill
                    sizes="100vw"
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        objectFit: 'cover',
                        overflow: 'hidden'
                    }}
                />
            </Box>
        </AuthenticationStyled>
    );
}
